<template>
	<div class="h100 flow4-centent">
		<ul class="h100 flow4 clearfix">
			<li class="item">
				<div class="value">
					<span class="partName">发电功率:</span>
					<span>{{ stationDetail.allActivePower }}W</span>
				</div>
				<div class="value">
					<span class="partName">装机容量:</span>
					<span>{{ stationDetail.stationEntity.capacity }}KWh</span>
				</div>
				<div class="iconBox iconBox-color1">
					<img src="../../assets/station/flow1.png"/>
				</div>
			</li>
			<li class="item"></li>
			<li class="item text-right">
				<div class="value">
					<span class="partName">电网</span>
				</div>
				<div class="value">
					<span>--KWh</span>
				</div>
				<div class="iconBox iconBox-color2">
					<img src="../../assets/station/flow2.png"/>
				</div>
			</li>

			<li class="item"></li>
			<li class="item">
				<div class="iconBox iconBox-color5 icon-center">
					<img src="../../assets/station/flow5.png"/>
				</div>
			</li>
			<li class="item"></li>

			<li class="item" style="padding-top: 20px">
				<div class="iconBox iconBox-color3">
					<img src="../../assets/station/flow3.png"/>
				</div>
				<div class="value">
					<span class="partName">电池</span>
				</div>
				<div class="value">
					<span>--KWh</span>
				</div>
			</li>
			<li class="item"></li>
			<li class="item text-right" style="padding-top: 20px">
				<div class="iconBox iconBox-color4">
					<img src="../../assets/station/flow4.png"/>
				</div>
				<div class="value">
					<span class="partName">用电</span>
				</div>
				<div class="value">
					<span>--KWh</span>
				</div>
			</li>
		</ul>
		<div ref="Temp" class="position left-top" name="发电" linkstate_="">
			<svg version="1.1" :viewBox="`0 0 ${tempWidth} ${tempHeight}`" class="dpB"
			     style="width: 100%; height: 100%;">
				<linearGradient id="style1-9" x1="0%" y1="0%" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
					<stop offset="0%" style="stop-color: rgb(65, 109, 228);"></stop>
					<stop offset="100%" style="stop-color: rgb(247, 162, 25);"></stop>
				</linearGradient>
				<path fill="none"
				      :d="`M 1,1 l 58.5,0 q 16,0 16,16 l 0,${tempHeight/2} q 0,${tempHeight5} ${tempHeight5},${tempHeight5} l ${tempWidth},0`"
				      stroke="url(#style1-9)" stroke-width="2" stroke-dasharray="0" stroke-linecap="round"
				      class="polyLine"></path>
			</svg>
			<div class="point"
			     :style="`offset-path: path(&quot;M 1 1 l 58.5 0 q 16 0 16 16 l 0 ${tempHeight/2} q 0 ${tempHeight5} ${tempHeight5} ${tempHeight5} l ${tempWidth} 0&quot;); animation-name: arrowAnimation;`"></div>
		</div>

		<div class="position right-top" name="电网" linkstate_="">
			<svg version="1.1" :viewBox="`0 0 ${tempWidth} ${tempHeight}`" class="dpB"
			     style="width: 100%; height: 100%;">
				<linearGradient id="style3-7" x1="0%" y1="100%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
					<stop offset="0%" style="stop-color: rgb(247, 162, 25);"></stop>
					<stop offset="100%" style="stop-color: rgb(15, 185, 176);"></stop>
				</linearGradient>
				<path fill="none"
				      :d="`M ${tempWidth},1 l -58.5,0 q -16,0 -16,16 l 0,${tempHeight/2} q 0,${tempHeight5} -${tempHeight5},${tempHeight5} l -${tempWidth},0`"
				      stroke="url(#style3-7)" stroke-width="2" stroke-dasharray="0" stroke-linecap="round"
				      class="polyLine"></path>
			</svg>
			<div class="point"
			     :style="`offset-path: path(&quot;M ${tempWidth} 1 l -58.5 0 q -16 0 -16 16 l 0 ${tempHeight/2} q 0 ${tempHeight5} -${tempHeight5} ${tempHeight5} l -${tempWidth} 0&quot;); animation-name: arrowAnimation1;`"></div>
		</div>

		<div class="position left-bottom" name="电池">
			<svg version="1.1" :viewBox="`0 0 ${tempWidth} ${tempHeight}`" class="dpB"
			     style="width: 100%; height: 100%;">
				<linearGradient id="style7-3" x1="0%" y1="100%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
					<stop offset="0%" style="stop-color: rgb(48, 191, 120);"></stop>
					<stop offset="100%" style="stop-color: rgb(247, 162, 25);"></stop>
				</linearGradient>
				<path fill="none"
				      :d="`M 1,${tempHeight-2} l 58.5,0 q 16,0 16,-16 l 0,-${tempHeight/2} q 0,-${tempHeight5} ${tempHeight5},-${tempHeight5} l ${tempWidth},0`"
				      stroke="url(#style7-3)" stroke-width="2" stroke-dasharray="0" stroke-linecap="round"
				      class="polyLine"></path>
			</svg>
			<div class="point"
			     :style="`offset-path: path(&quot;M 1 ${tempHeight-2} l 58.5 0 q 16 0 16 -16 l 0 -${tempHeight/2} q 0 -${tempHeight5} ${tempHeight5} -${tempHeight5} l ${tempWidth} 0&quot;); animation-name: arrowAnimation;`"></div>
		</div>
		<div class="position right-bottom" name="use">
			<svg version="1.1" :viewBox="`0 0 ${tempWidth} ${tempHeight}`" class="dpB"
			     style="width: 100%; height: 100%;">
				<linearGradient id="style9-1" x1="0%" y1="0%" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
					<stop offset="0%" style="stop-color: rgb(247, 162, 25);"></stop>
					<stop offset="100%" style="stop-color: rgb(247, 100, 87);"></stop>
				</linearGradient>
				<path fill="none"
				      :d="`M ${tempWidth},${tempHeight-2} l -58.5,0 q -16,0 -16,-16 l 0,-${tempHeight/2} q 0,-${tempHeight5} -${tempHeight5},-${tempHeight5} l -${tempWidth},0`"
				      stroke="url(#style9-1)" stroke-width="2" stroke-dasharray="0" stroke-linecap="round"
				      class="polyLine"></path>
			</svg>
			<div class="point"
			     :style="`offset-path: path(&quot;M ${tempWidth} ${tempHeight-2} l -58.5 0 q -16 0 -16 -16 l 0 -${tempHeight/2} q 0 -${tempHeight5} -${tempHeight5} -${tempHeight5} l -${tempWidth} 0&quot;); animation-name: arrowAnimation1;`"></div>
		</div>
		<!-- <img class="left-top" src="@/assets/station/left-top2.svg" alt="" />
		<img class="right-top" src="@/assets/station/right-top2.svg" alt="" />
		<img class="left-bottom" src="@/assets/station/left-bottom2.svg" alt="" />
		<img class="right-bottom" src="@/assets/station/right-bottom2.svg" alt="" /> -->
	</div>
</template>

<script>
export default {
	props: {
		stationDetail: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			tempWidth: 0,
			tempHeight: 0,
			tempHeight5: 0,
		};
	},
	mounted() {
		// 进入页面元素默认宽高
		this.tempWidth = this.$refs.Temp.offsetWidth;
		this.tempHeight = this.$refs.Temp.offsetHeight;
		this.tempHeight5 = this.$refs.Temp.offsetHeight / 5;
		//根据屏幕缩放自动获取页面宽高
		window.onresize = () => {
			return (() => {
				//窗口缩放自动获取元素宽高
				this.tempWidth = this.$refs.Temp.offsetWidth; //宽
				this.tempHeight = this.$refs.Temp.offsetHeight; //宽
				this.tempHeight5 = this.$refs.Temp.offsetHeight / 5;
			}) ();
		};
	},
};
</script>


<style lang="less" scoped>
.flow4-centent {
	position: relative;
}

.flow4 {
	position: relative;
	z-index: 1;
	margin-bottom: 0;

	.item {
		position: relative;
		float: left;
		width: 33.3%;
		height: 33.3%;

		.value {
			color: #515561;

			.partName {
				color: #95929c;
				white-space: nowrap;
			}
		}

		.iconBox {
			position: relative;
			width: 60px;
			height: 60px;
			text-align: center;
			border-radius: 50%;
			display: inline-block;

			&.iconBox-color1 {
				background-color: #ebf0fd;
			}

			&.iconBox-color2 {
				background-color: #e6f8f7;
			}

			&.iconBox-color3 {
				background-color: #eaf9f1;
			}

			&.iconBox-color4 {
				background-color: #ffefee;
			}

			&.iconBox-color5 {
				background-color: #fef6e8;
			}

			img {
				width: 30px;
				height: 30px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.icon-center {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.position {
	overflow: hidden;
	height: 130px;
}

.left-top {
	position: absolute;
	top: 75px;
	left: 60px;
	width: calc(50% - 90px);
}

.right-top {
	position: absolute;
	top: 75px;
	right: 60px;
	width: calc(50% - 90px);
}

.left-bottom {
	position: absolute;
	bottom: 75px;
	left: 60px;
	width: calc(50% - 90px);
}

.right-bottom {
	position: absolute;
	bottom: 75px;
	right: 60px;
	width: calc(50% - 90px);
}

.point {
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	background-color: #fff;
	border: 1px solid;
	border-radius: 50%;
	-webkit-animation: 3s ease-in-out infinite;
	animation: 3s ease-in-out infinite;
	offset-distance: 0;
}
</style>
