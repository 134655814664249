<template>
	<div class="flow-box">
		<div class="clearfix flow-box-row1">
			<div class="floatl flex-alignc">
				<div class="flow-bg flow-bg1 mr-10">
					<div class="icon">
						<img src="../../assets/station/flow1.png"/>
					</div>
					<div class="ball-bg">
						<div class="num">
							{{
								(
									(stationDetail.allActivePower /
										stationDetail.stationEntity.capacity) / 10
								).toFixed ( 2 )
							}}%
						</div>
					</div>
				</div>

				<div>
					<div>
						<span title="发电功率">发电功率</span>
						<span :title="stationDetail.allActivePower"
						>{{ (stationDetail.allActivePower / 1000).toFixed(2) }}KWh</span
						>
					</div>
					<div>
						<span title="装机容量">装机容量</span>
						<span :title="stationDetail.stationEntity.capacity + 'KWh'"
						>{{ stationDetail.stationEntity.capacity }} KWh</span
						>
					</div>
				</div>
			</div>

			<div class="floatr flex-alignc">
				<div class="mr-10">
					<div>
						<span title="电网功率">并网量</span>
					</div>
					<div>
						<span title="KWh">{{ stationDetail.fpFKwh }} KWh</span>
					</div>
				</div>

				<div class="flow-bg flow-bg2">
					<div class="icon icon-center">
						<img src="../../assets/station/flow2.png"/>
					</div>
				</div>
			</div>
		</div>

		<div class="clearfix flex flow-box-row2">
			<div ref="Temp" class="flex-sub1 posR">
				<div>
					<svg
						version="1.1"
						:viewBox="`0 0 ${tempWidth} 56`"
						style="width: 100%; height: 56px"
					>
						<linearGradient
							id="styleTR"
							x1="0%"
							y1="0%"
							x2="100%"
							y2="100%"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0%" style="stop-color: rgb(65, 109, 228)"></stop>
							<stop offset="100%" style="stop-color: rgb(247, 162, 25)"></stop>
						</linearGradient>
						<path
							fill="none"
							:d="`M 2 2 L 2 39 q 2 16 18 16 M 18 55 L ${tempWidth} 55`"
							stroke="url(#styleTR)"
							stroke-width="2"
							stroke-dasharray="0"
							stroke-linecap="round"
							class="polyLine"
						></path>
					</svg>
					<div
						class="point"
						:style="`offset-path: path(&quot;M 2 2 L 2 39 q 2 16 18 16 M 18 55 L ${tempWidth} 55&quot;); animation-name: arrowAnimation;`"
					></div>
				</div>
				<!-- <div>
				  <svg
					version="1.1"
					:viewBox="`0 0 ${tempWidth} 56`"
					style="width: 100%; height: 56px"
				  >
					<linearGradient
					  id="styleTR"
					  x1="0%"
					  y1="0%"
					  x2="100%"
					  y2="100%"
					  gradientUnits="userSpaceOnUse"
					>
					  <stop offset="0%" style="stop-color: rgb(65, 109, 228)"></stop>
					  <stop offset="100%" style="stop-color: rgb(247, 162, 25)"></stop>
					</linearGradient>
					<path
					  fill="none"
					  :d="`M 2 2 L 2 39 q 2 16 18 16 M 18 55 L ${tempWidth} 55`"
					  stroke="rgba(0,0,0,.2)"
					  stroke-width="2"
					  stroke-dasharray="0"
					  stroke-linecap="round"
					  class=""
					></path>
				  </svg>
				</div> -->
			</div>

			<div class="flow-bg flow-bg3">
				<div class="icon icon-center">
					<img src="../../assets/station/flow5.png"/>
				</div>
			</div>

			<div class="flex-sub1 posR">
				<div class="trsY180">
					<svg
						version="1.1"
						:viewBox="`0 0 ${tempWidth} 56`"
						style="width: 100%; height: 56px"
					>
						<linearGradient
							id="styleTL"
							x1="0%"
							y1="0%"
							x2="100%"
							y2="100%"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0%" style="stop-color: rgb(15, 185, 176)"></stop>
							<stop offset="100%" style="stop-color: rgb(247, 162, 25)"></stop>
						</linearGradient>
						<path
							fill="none"
							:d="`M 2 2 L 2 39 q 2 16 18 16 M 18 55 L ${tempWidth} 55`"
							stroke="url(#styleTL)"
							stroke-width="2"
							stroke-dasharray="0"
							stroke-linecap="round"
							class="polyLine"
						></path>
					</svg>
					<div
						class="point"
						:style="`offset-path: path(&quot;M 2 2 L 2 39 q 2 16 18 16 M 18 55 L ${tempWidth} 55&quot;); animation-name: arrowAnimation;`"
					></div>
				</div>
			</div>
		</div>

		<div class="flow-box-row3">
			<div class="flow-box-row3-line">
				<div ref="Temp1">
					<svg
						version="1.1"
						:viewBox="`0 0 ${tempWidth1} 50`"
						width="40"
						class=""
						style="width: 100%; height: 50px"
					>
						<linearGradient
							id="styleTB"
							x1="50%"
							y1="0%"
							x2="50%"
							y2="100%"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0%" style="stop-color: rgb(247, 162, 25)"></stop>
							<stop offset="100%" style="stop-color: rgb(247, 100, 87)"></stop>
						</linearGradient>
						<path
							fill="none"
							:d="`M${tempWidth1 / 2} 0 V21 50`"
							stroke="url(#styleTB)"
							stroke-width="2"
							stroke-dasharray="0"
							stroke-linecap="round"
							class="polyLine"
						></path>
					</svg>
					<div
						class="point"
						:style="`offset-path: path('M ${
              tempWidth1 / 2
            } 0 V 21 V 50');animation-name: arrowAnimation;`"
					></div>
				</div>
			</div>
		</div>

		<div class="flow-box-row4 flex-alignc">
			<div class="flow-bg flow-bg4 mr-10">
				<div class="icon icon-center">
					<img src="../../assets/station/flow4.png"/>
				</div>
			</div>
			<div class="flow-text">
				<div>
					<span title="用电功率">用电量</span>
				</div>
				<div>
					<span title="KWh">{{
							new Number ( stationDetail.allPower ) + new Number ( stationDetail.rfpFKwh )
						}}KWh</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		stationDetail: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			tempWidth: 0,
			tempWidth1: 0,
		};
	},
	mounted() {
		var that = this;
		// 进入页面元素默认宽高
		this.tempWidth = this.$refs.Temp.offsetWidth;
		this.tempWidth1 = this.$refs.Temp1.offsetWidth;

		//根据屏幕缩放自动获取页面宽高
		window.onresize = () => {
			return (() => {
				//窗口缩放自动获取元素宽高
				this.tempWidth = this.$refs.Temp.offsetWidth; //宽
				this.tempWidth1 = this.$refs.Temp1.offsetWidth; //宽
			}) ();
		};
	},
};
</script>

<style lang="less" scoped>
.flow-box {
	padding: 15px 15px 0 15px;

	.point {
		position: absolute;
		top: 0px;
		width: 10px;
		height: 10px;
		background-color: rgb(255, 255, 255);
		border: 1px solid;
		border-radius: 50%;
		animation: 3s ease-in-out 0s infinite normal none running none;
		offset-distance: 0px;
	}

	.flow-bg {
		position: relative;
		top: 0;
		display: inline-block;
		width: 80px;
		height: 80px;
		text-align: center;
		border: 1px solid transparent;
		border-radius: 50%;

		&.flow-bg1 {
			background-color: #ecf0fc;
		}

		&.flow-bg2 {
			background-color: #e7f8f7;
		}

		&.flow-bg3 {
			background-color: #fef6e8;
		}

		&.flow-bg4 {
			background-color: #feefee;
		}

		.icon {
			width: 30px;
			height: 30px;
			position: absolute;
			top: 20%;
			left: 50%;
			transform: translateX(-50%);

			img {
				width: 100%;
				height: 100%;
				margin-top: -10px;
			}

			&.icon-center {
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.ball-bg {
			position: relative;
			width: 100%;
			height: 100%;
			top: -1px;
			left: -1px;

			.num {
				width: 100%;
				height: 100%;
				padding-top: 45px;
			}
		}
	}

	.flow-box-row2 {
		padding: 0 40px;

		.posR {
			position: relative;
			height: 56px;

			.trsY180 {
				transform: rotateY(180deg);
			}
		}

		.flow-bg {
			transform: translateY(40px);
		}
	}

	.flow-box-row3 {
		margin-top: 40px;
		text-align: center;

		.flow-box-row3-line {
			width: 80px;
			height: 50px;
			position: relative;
			margin: 0 auto;
		}
	}

	.flow-box-row4 {
		position: relative;

		.flow-bg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		.flow-text {
			position: absolute;
			left: 50%;
			transform: translate(50px, 40px);
		}
	}
}
</style>
