
import * as echarts from "echarts";
// 整体发电概况
export const powerSurveyEchart = (echartData) => {
    return {
        series: [
            {
                type: "liquidFill",
                radius: "78.1%",
                center: ["50%", "50%"],
                color: ["#ecf3fe", "#c8dcfe", "#5594fa"],
                data: [echartData, echartData, echartData], // data个数代表波浪数
                amplitude: 5,
                // 图形样式
                itemStyle: {
                    opacity: 1, // 波浪的透明度
                    shadowBlur: 0, // 波浪的阴影范围
                },
                backgroundStyle: {
                    borderWidth: 2,
                    borderColor: "#709DFA",
                    color: "#fff",
                },
                label: {
                    show: true,
                    textStyle: {
                        color: "#515561",
                        insideColor: "#515561",
                        fontSize: 15,
                    },
                    formatter: (params) => {
                        return `${(params.value * 100).toFixed(2)}%`;
                    },
                },
                outline: {
                    show: false,
                },
            },
        ],
    }
}

// 历史折线图
export const historyEchart = (echartData) => {
    return {
        title: {
            text: 'kWh'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        legend: {
            data: ['发电量','发电功率'],
            // data: ['发电量', '发电功率','天气'],
            itemWidth: '10',
            itemHeight: '10',
            bottom: "1",
            align: 'right',
            textStyle: {
                color: '#95929c',
                fontSize: '11',
            },
            padding: [5, 0, 0, 0]
        },
        grid: {
            // left: "30px",
            left: "70px", // 与容器左侧的距离
            right: "30px",
            bottom: "40px",
            top: "30px"
        },
        xAxis: [
            {
                type: 'category',
                data: echartData.xData,
                axisPointer: {
                    type: 'shadow'
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    fontSize: 10
                }
            }
        ],
        yAxis: [
            {
                type: 'value', //设置分割线虚线效果
            }
        ],
        series: [
            {
                name: '发电量',
                type: 'line',
                smooth: true,
                stack: 'Total',
                areaStyle: {},
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kWh';
                    }
                },
                itemStyle: {
                    color: "#048fff",
                },
                areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#048fff" },
                        { offset: 0.7, color: "#d3ecff" },
                        { offset: 1, color: "#ffffff" },
                      ])
                    }
                },
                data: echartData.yData['0']
            },
            {
                name: '发电功率',
                type: 'line',
                smooth: true,
                stack: 'Total',
                areaStyle: {},
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kWh';
                    }
                },
                itemStyle: {
                    color: "#ed1c24",
                },
                areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#ed1c24" },
                        { offset: 0.7, color: "#fee9e9" },
                        { offset: 1, color: "#ffffff" },
                      ])
                    }
                },
                data: echartData.yData['1']
            }
            // 天气暂时没有
            // {
            //     name: '天气',
            //     type: 'line',
            //     stack: 'Total',
            //     areaStyle: {},
            //     tooltip: {
            //         valueFormatter: function (value) {
            //             return value + ' kWh';
            //         }
            //     },
            //     itemStyle: {
            //         color: "#d9d0e0",
            //     },
            //     areaStyle: {
            //         normal: {
            //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //             { offset: 0, color: "#d9d0e0" },
            //             { offset: 0.7, color: "#e6e3ed" },
            //             { offset: 1, color: "#ffffff" },
            //           ])
            //         }
            //     },
            //     data: [150, 232, 201, 154, 190, 330, 410, 400]
            // },
        ]
    }
};

// 历史柱状图
export const historyEchartColumnar = (echartData) => {
    console.log(echartData)
    return {
        title: {
            text: 'kWh'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        xAxis: {
            type: "category",
            data: echartData.xData,
            axisTick: {
                show: false
            },
            axisLabel: {
                fontSize: 10
            }
        },
        grid: {
            left: "70px",
            right: "0px",
            bottom: "40px",
            top: "30px",
            // containLabel: true,
        },
        yAxis: {
            type: "value",
            nameTextStyle: {
                align: "left",
                padding: [0, 0, 3, -30],
                // fontSize: '11'
            },
            axisLabel: {
                fontSize: '11'
            },
            splitNumber: 2
        },
        legend: {
            // data: ['发电量','发电功率'],
            data: ['发电量'],
            bottom: "4",
            itemWidth: '10',
            itemHeight: '10',
            itemStyle: {
                color: '#048FFF'
            },
            align: 'right',
            textStyle: {
                color: '#95929c',
                fontSize: '11',
            },
            padding: [5, 0, 0, 0]

        },
        series: [
            {
                data: echartData.yData['0'],
                type: "bar",
                backgroundStyle: {
                    color: "#1DA8FF",
                },
                itemStyle: {
                    color: "#048FFF",
                    borderRadius: [4, 4, 0, 0],
                },
                barWidth: "12",
                name: '发电量',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + 'kWh';
                    }
                },
            },
        ],
    }
}

// 计划
export const planPowerEchart = () => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        legend: {
            data: ['当月发电量', '当月计划发电量', '当月计划完成率'],
            itemWidth: '10',
            itemHeight: '10',
            // itemStyle: {
            //     color: '#048FFF'
            // },
            bottom: "4",
            align: 'right',
            textStyle: {
                color: '#95929c',
                fontSize: '11',
            },
            padding: [5, 0, 0, 0]
        },
        grid: {
            left: "30px",
            right: "30px",
            bottom: "40px",
            top: "30px",
            // containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisPointer: {
                    type: 'shadow'
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    fontSize: 10
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'kWh',
                splitNumber: 4,
                nameTextStyle: {
                    align: "left",
                },
                axisLabel: {
                    fontSize: '11'
                },
            },
            {
                type: 'value',
                name: '%',
                splitNumber: 4,
                nameTextStyle: {
                    align: "left",
                },
            }
        ],
        series: [
            {
                name: '当月发电量',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kWh';
                    }
                },
                data: [
                    2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                ],
                itemStyle: {
                    color: "#048FFF",
                    borderRadius: [4, 4, 0, 0],
                },
                barWidth: "12",
            },
            {
                name: '当月计划发电量',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kWh';
                    }
                },
                data: [
                    2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                ],
                itemStyle: {
                    color: "#ED3333",
                    borderRadius: [4, 4, 0, 0],
                },
                barWidth: "12",

            },
            {
                name: '当月计划完成率',
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' %';
                    }
                },
                data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
                itemStyle: {
                    color: '#9E5ED9'
                },
                symbol: 'circle',   //将小圆点改成实心 不写symbol默认空心
                symbolSize: 8,    //小圆点的大小
            }
        ]
    };
}
