<template>
    <div class="flex-content">
        <!-- 头部 -->
        <div class="section-header clearfix">
            <div class="fL">
                <div class="flex-alignc">
                    <div class="lh13">{{ stationDetail.stationEntity.stationName }}</div>
                    <div class="fL-id ml-16">ID{{ stationDetail.stationEntity.id }}</div>
                    <!-- <div>外部授权电站，点击查看权限</div> -->
                </div>
                <div class="flex-alignc mb-16">
                    <div class="mr-16" v-if="stationDetail.stationEntity.communicate">
                        <template v-if="stationDetail.stationEntity.communicate == 1">
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#41D068' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            正常
                        </template>
                        <template v-else-if="stationDetail.stationEntity.communicate == 2">
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#f76558' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            部分设备离线
                        </template>
                        <template v-else-if="stationDetail.stationEntity.communicate == 3">
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#f76558' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            全部设备离线
                        </template>
                        <template v-else-if="stationDetail.stationEntity.communicate == 4">
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#f78615' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            接入中
                        </template>
                    </div>
                    <div class="mr-16">
                        <template v-if="stationDetail.stationEntity.alarmFlag == 1">
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#f76558' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            有报警
                        </template>
                        <template v-else>
                            <a-icon
                                    class="mr-10"
                                    :style="{ color: '#41D068' }"
                                    theme="filled"
                                    type="minus-circle"
                            />
                            无报警
                        </template>
                    </div>
                </div>
                <div v-if="stationDetail.stationEntity">
                    <a-tag
                            color="blue"
                            v-for="(item, index) in stationDetail.stationEntity.labelEntities"
                            :key="index"
                    >{{ item.labelName }}
                    </a-tag
                    >
                </div>
            </div>
            <div class="fR">
                <div class="mb-16">
                    <a-button title="刷新" class="mr-16" size="small" @click="init">
                        <a-icon type="sync"/>
                    </a-button>
                    <a-dropdown>
                        <a-button class="mr-16" size="small">添加</a-button>
                        <a-menu slot="overlay">
                            <a-menu-item key="1" @click="openModal(1)">采集器</a-menu-item>
                        </a-menu>
                        <a-icon slot="icon" type="user"/>
                    </a-dropdown>
                    <a-dropdown>
                        <a-button class="mr-16" size="small">更多</a-button>
                        <a-menu slot="overlay" @click="handleClickMore">
                            <a-menu-item key="1">删除电站</a-menu-item>
                        </a-menu>
                        <a-icon slot="icon" type="user"/>
                    </a-dropdown>
                </div>
                <div>最后更新 {{ moment().format("YYYY-MM-DD HH:mm:ss") }}</div>
            </div>
        </div>

        <div class="flex-main">
            <div class="gutter-example">
                <!-- 电站封面 -->
                <a-row :gutter="16" class="mb-16 station-row">
                    <a-col :span="6" class="h100">
                        <div class="bgfff flex-content">
                            <div class="station-cover">
                                <div class="cover-text" title="电站封面">电站封面</div>
                                <img
                                        :src="
                    stationDetail.stationEntity.coverImg
                      ? stationDetail.stationEntity.coverImg
                      : require('../assets/station/cover.jpg')
                  "
                                        alt=""
                                        class="cover-img"
                                />
                            </div>
                            <ul class="station-msg">
                                <li class="flex-alignc-justifyb">
                                    <div class="lable">地址</div>
                                    <div>{{ stationDetail.stationEntity.address }}</div>
                                </li>
                                <li class="flex-alignc-justifyb">
                                    <div class="lable">电站类型</div>
                                    <div>
                    <span v-if="stationDetail.stationEntity.stationType == 1"
                    >分布式户用</span
                    >
                                        <span
                                                v-else-if="stationDetail.stationEntity.stationType == 2"
                                        >分布式商用</span
                                        >
                                        <span
                                                v-else-if="stationDetail.stationEntity.stationType == 3"
                                        >分布式工业</span
                                        >
                                        <span
                                                v-else-if="stationDetail.stationEntity.stationType == 4"
                                        >地面电站</span
                                        >
                                    </div>
                                </li>
                                <li class="flex-alignc-justifyb">
                                    <div class="lable">系统类型</div>
                                    <div>
                    <span v-if="stationDetail.stationEntity.systemType == 1"
                    >光伏+电网</span
                    >
                                        <span
                                                v-else-if="stationDetail.stationEntity.systemType == 2"
                                        >光伏+电网+用电</span
                                        >
                                        <span
                                                v-else-if="stationDetail.stationEntity.systemType == 3"
                                        >光伏+电网+用电+储能</span
                                        >
                                    </div>
                                </li>
                                <li class="flex-alignc-justifyb">
                                    <div class="lable">业主联系电话</div>
                                    <div>
                                        {{
                                        stationDetail.stationEntity.telPhone
                                        ? stationDetail.stationEntity.telPhone
                                        : "--"
                                        }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </a-col>

                    <a-col :span="12" class="h100">
                        <div class="bgfff flex-content">
                            <ul class="clearfix pd-20 flow-tab">
                                <template v-for="(item, index) in systemType">
                                    <li
                                            :class="systemTypeValue == item.value ? 'on' : ''"
                                            :key="index"
                                            @click="handleSystemType(item)"
                                            class="floatl pointer"
                                            v-if="item.show"
                                    >
                                        {{ item.label }}
                                    </li>
                                </template>
                            </ul>

                            <div class="flow-content flex-main" style="padding-top: 0">
                                <!-- 流动图 -->
                                <template v-if="systemTypeValue == 1">
                                    <Flow
                                            v-if="stationDetail.stationEntity.systemType == 2"
                                            :stationDetail="stationDetail"
                                    ></Flow>
                                    <Flow4
                                            v-else-if="stationDetail.stationEntity.systemType == 3"
                                            :stationDetail="stationDetail"
                                    ></Flow4>
                                </template>

                                <!-- 发电实时 -->
                                <div class="h100" v-else-if="systemTypeValue == 2">
                                    <div class="data-echarts">
                                        <div class="echarts-left">
                                            <div id="waterpolo" ref="waterpolo"></div>
                                        </div>
                                        <div class="echarts-right">
                                            <div class="center-box">
                                                <div class="total-power">
                                                    <div class="total-title">发电功率</div>
                                                    <div class="total-num">
                                                        <span>{{ (stationDetail.allActivePower/1000).toFixed(2) }}</span> kWh
                                                    </div>
                                                </div>
                                                <div class="right-line"></div>
                                                <div class="total-power">
                                                    <div>装机容量</div>
                                                    <div class="total-num">
                            <span>
                              {{ stationDetail.stationEntity.capacity }}
                            </span>
                                                        kWh
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row-line"></div>
                                    <div class="statistics-box">
                                        <div class="one-box" style="background: rgb(245, 251, 255)">
                                            <div>当日发电量</div>
                                            <div class="statistics-num">
                        <span>{{ stationDetail.dayPower }}</span
                        >kWh
                                            </div>
                                        </div>
                                        <div class="one-box" style="background: rgb(255, 248, 247)">
                                            <div>当月发电量</div>
                                            <div class="statistics-num">
                        <span>{{ stationDetail.monthPower }}</span
                        >kWh
                                            </div>
                                        </div>
                                        <div class="one-box" style="background: rgb(246, 245, 255)">
                                            <div>当年发电量</div>
                                            <div class="statistics-num">
                        <span>{{ stationDetail.yearPower }}</span
                        >kWh
                                            </div>
                                        </div>
                                        <div class="one-box" style="background: rgb(245, 250, 246)">
                                            <div>累计发电量</div>
                                            <div class="statistics-num">
                        <span>{{ stationDetail.allPower }}</span
                        >kWh
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 电网实时/电池实时 暂无字段先空着 -->
                                <div class="h100 systemType4" v-else-if="systemTypeValue == 4">
                                    <div class="flex-alignc systemType-row1">
                                        <div class="flex-alignc mr-16">
                                            <div class="img img1">
                                                <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        data-inject-url="https://pro.solarmanpv.com/static/icons/eTower.svg"
                                                        data-v-93dd90c6=""
                                                        class="vaM tra-fast w100pct h100pct dpB"
                                                >
                                                    <g data-name="图层 2">
                                                        <g data-name="图层 1">
                                                            <path
                                                                    data-name="Clip 4"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="none"
                                                            ></path>
                                                            <path
                                                                    d="M4.11 22.5a1 1 0 110-1.92H6L8.86 3.43h-2.2v1.31a.74.74 0 01-.72.78.75.75 0 01-.79-.72V2.44a1 1 0 010-.17.94.94 0 01.91-.77H17.8a.94.94 0 01.92.77 1 1 0 010 .17v2.3a.76.76 0 01-1.51 0V3.43H15l2.86 17a.37.37 0 010 .11h2a1 1 0 010 1.92zm7.33-9.5v.37c-.24.84-.48 1.68-.7 2.51v.11a.17.17 0 000 .14.16.16 0 00.14.06.17.17 0 00.14-.07l3.31-4.56a.15.15 0 000-.16.2.2 0 00-.15-.1h-.91a.94.94 0 00-.22 0h-.4a.63.63 0 01-.33-.06.73.73 0 01.08-.38.76.76 0 000-.11c.05-.17.1-.35.14-.52 0-.16.09-.32.14-.49l.41-1.48a.14.14 0 000-.14.2.2 0 00-.14-.06.17.17 0 00-.14.07L9.43 12.7a.14.14 0 000 .15.18.18 0 00.15.1h1.66a.25.25 0 01.2.05zM4.7 9.5a.6.6 0 01-.6-.6.6.6 0 01.6-.6h2a.59.59 0 01.58.6.63.63 0 01-.17.42.59.59 0 01-.42.17zm12.5-.07a.6.6 0 010-1.2h2a.6.6 0 010 1.2zM4.11 7.66a.7.7 0 010-1.4h3.14a.7.7 0 010 1.4zM16.6 7.6a.7.7 0 110-1.4h3.15a.7.7 0 010 1.4z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div><!--静止--></div>
                                        </div>
                                    </div>
                                    <div class="banner-list">
                                        <div class="bgf8f8 flex item">
                                            <div class="left flex-alignc">
                                                <span>并网量</span>
                                            </div>
                                            <div class="power-num">{{ fpFKwh }} kwh</div>
                                        </div>
                                        <div class="bgf8f8 flex item">
                                            <div class="left flex-alignc">
                                                <span>购网量</span>
                                            </div>
                                            <div class="power-num">{{ rfpFKwh }} kwh</div>
                                        </div>
                                        <div class="bgf8f8 flex item">
                                            <div class="left flex-alignc">
                                                <span>用电量</span>
                                            </div>
                                            <div class="power-num">{{ usePower }} kwh</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="h100 systemType4" v-else-if="systemTypeValue == 5">
                                    <div class="flex-alignc systemType-row1">
                                        <div class="flex-alignc mr-16">
                                            <div class="img img2">
                                                <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        data-inject-url="https://pro.solarmanpv.com/static/icons/dv-battery.svg"
                                                        data-v-93dd90c6=""
                                                        class="vaM tra-fast w100pct h100pct dpB"
                                                >
                                                    <g data-name="图层 2">
                                                        <g data-name="图层 1">
                                                            <path
                                                                    data-name="矩形 266"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="#fff1de"
                                                                    fill-opacity="0"
                                                            ></path>
                                                            <path
                                                                    d="M2.81 20.47a1.31 1.31 0 01-1.31-1.31V7.83a1.31 1.31 0 011.31-1.31h18.38a1.31 1.31 0 011.31 1.31v11.33a1.31 1.31 0 01-1.31 1.31zM15.5 14h1.75v1.5h1.17V14h1.75v-1h-1.75v-1.5h-1.17V13H15.5zM3.83 14H8.5v-1H3.83zM15.5 5.52V4.18a.65.65 0 01.66-.65h3.35a.66.66 0 01.66.65v1.34zm-11.67 0V4.18a.66.66 0 01.66-.65h3.35a.65.65 0 01.66.65v1.34z"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div>放电</div>
                                        </div>
                                        <div class="text">
                                            <span>电池功率</span>
                                            <span>--</span>
                                            <span>kW</span>
                                        </div>
                                    </div>
                                    <div class="banner-list">
                                        <div class="bgf8f8 flex item">
                                            <div class="left flex-alignc">
                                                <span>充电量</span>
                                            </div>
                                            <div class="flex-sub1 flex-alignc">
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bgf8f8 flex item">
                                            <div class="left flex-alignc">
                                                <span>放电量</span>
                                            </div>
                                            <div class="flex-sub1 flex-alignc">
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                                <div class="flex-sub1 right-item">
                                                    <div>当日</div>
                                                    <div><span>--</span> kWh</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>

                    <a-col :span="6" class="h100">
                        <div class="bgfff h100">
                            <div id="he-plugin-standard"></div>
                        </div>
                    </a-col>
                </a-row>

                <!-- 历史 -->
                <a-row :gutter="16" class="mb-16">
                    <a-col :span="24">
                        <div class="bgfff">
                            <div class="flex-alignc-justifyb pd-20">
                                <div>历史</div>
                                <div class="flex-alignc">
                                    <a-radio-group
                                            size="small"
                                            v-model="timeType"
                                            @change="changeTimeType"
                                    >
                                        <a-radio-button value="1">日</a-radio-button>
                                        <a-radio-button value="2">月</a-radio-button>
                                        <a-radio-button value="3">年</a-radio-button>
                                    </a-radio-group>
                                    <a-button
                                            class="ml-16 mr-16"
                                            size="small"
                                            type="primary"
                                            @click="handleExport"
                                    >导出
                                    </a-button
                                    >
                                    <div class="time-picker">
                                        <a-icon class="mr-16" type="left" @click="dealYM(-1)"/>
                                        <a-date-picker
                                                :allowClear="false"
                                                format="YYYY-MM-DD"
                                                v-if="timeType == '1'"
                                                v-model="historyTime"
                                                @change="changeTime"
                                        />
                                        <a-month-picker
                                                :allowClear="false"
                                                format="YYYY-MM"
                                                v-else-if="timeType == '2'"
                                                v-model="historyTime"
                                        />
                                        <a-date-picker
                                                :allowClear="false"
                                                :open="open"
                                                @openChange="openChange"
                                                @panelChange="panelChange"
                                                format="YYYY"
                                                mode="year"
                                                v-else-if="timeType == '3'"
                                                v-model="historyTime"
                                        />
                                        <a-range-picker
                                                :default-value="['2015', '2025']"
                                                disabled
                                                format="YYYY"
                                                style="width: 120px"
                                                v-else
                                        />
                                        <a-icon class="ml-16" type="right" @click="dealYM(1)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="pd-20">
                                <a-row
                                        align="top"
                                        class="history-tab"
                                        justify="center"
                                        type="flex"
                                >
                                    <a-col :span="4" align="center">
                                        <div class="right-line">
                                            <div>
                                                <span v-if="timeType == 1">当日</span>
                                                <span v-else-if="timeType == 2">当月</span>
                                                <span v-else-if="timeType == 3">当年</span>
                                                <span>发电量</span>
                                            </div>
                                            <div class="num">
                        <span v-if="timeType == 1">{{
                          historyData.dayPower
                        }}</span>
                                                <span v-else-if="timeType == 2">{{
                          historyData.monthPower
                        }}</span>
                                                <span v-else-if="timeType == 3">{{
                          historyData.yearPower
                        }}</span>
                                                kWh
                                            </div>
                                        </div>
                                    </a-col>
                                    <!--<a-col :span="4" align="center">
                                        <div>
                                            <div>当日满发小时</div>
                                            <div class="num">
						                        <span>{{ historyData.fullHour }}</span>h
                                            </div>
                                        </div>
                                    </a-col>-->
                                </a-row>
                            </div>
                            <div class="echarts-box pd-20">
                                <div id="history"></div>
                            </div>
                        </div>
                    </a-col>
                </a-row>

                <!-- 分析 -->
                <a-row :gutter="16" class="mb-16" style="height: 230px">

                    <a-col :span="12" class="h100">
                        <div class="h100 bgfff">
                            <div class="bgfff h100 pd-20 flex-directionc">
                                <div class="flex-alignc-justifyb">
                                    <div>工单</div>
                                    <a-icon type="right"/>
                                </div>

                                <div class="flex-sub1 flex-alignc-justifya work-order">
                                    <div class="text-center">
                                        <div class="num numl">{{ taskExecutionNum }}</div>
                                        <div>执行中</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="num numr">{{ taskCompletedNum }}</div>
                                        <div>已完成</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12" class="h100">
                        <div class="bgfff h100 pd-20 flex-directionc">
                            <div>
                                <span>节能减排及收入</span>
                                <a-icon type="question-circle" @click="energyShow = true"/>
                            </div>
                            <div class="flex-sub1 flex-wrap energy">
                                <div class="energy-item flex-alignc">
                                    <div class="item-img">
                                        <svg
                                                class="vaM tra-fast w100pct h100pct dpB"
                                                data-inject-url="https://pro.solarmanpv.com/static/icons/coal.svg"
                                                viewBox="0 0 15.4 15.4"
                                                xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M0 15.4h15.4V0H0z" fill="none"/>
                                            <path
                                                    clip-rule="evenodd"
                                                    d="M7.7 1.1c.2 0 .4.1.5.3l6 11.8c.1.1.1.3.1.4 0 .3-.2.6-.6.7H9.2V7.1c0-.1 0-.1-.1-.2 0 0-.1-.1-.2-.1H4.4l2.8-5.4c.1-.2.3-.3.5-.3zm.6 11.6v1.6H1.1v-1.6h7.2zm0-2.5v1.6H1.1v-1.7l7.2.1zm0-2.5v1.6H1.1V7.7h7.2z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <div>节约标准煤</div>
                                        <div>
                      <span>{{
                        (stationDetail.allPower * 0.000305).toFixed(2)
                      }}</span>
                                            吨
                                        </div>
                                    </div>
                                </div>
                                <div class="energy-item flex-alignc">
                                    <div class="item-img">
                                        <svg
                                                class="vaM tra-fast w100pct h100pct dpB"
                                                data-inject-url="https://pro.solarmanpv.com/static/icons/CO2.svg"
                                                data-v-d7165444
                                                viewBox="0 0 15.41 10.22"
                                                xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                    class="cls-1"
                                                    d="M14.05 5.33a1.49 1.49 0 00-1.35-2.12A3.43 3.43 0 009.33.66 3.47 3.47 0 006.59 2a2.56 2.56 0 00-1.26-.32A2.72 2.72 0 002.7 5.06a2.93 2.93 0 00.52 5.82h9.54a2.92 2.92 0 001.29-5.55zM4.74 7a.91.91 0 00.65.27 1 1 0 00.87-.43l.45.47a1.71 1.71 0 01-1.3.61 1.67 1.67 0 01-1.22-.47 1.55 1.55 0 01-.49-1.23A1.59 1.59 0 014.19 5a1.62 1.62 0 011.2-.48 1.7 1.7 0 011.34.61l-.46.5a1 1 0 00-.84-.42.82.82 0 00-.68.28 1 1 0 00-.28.72 1 1 0 00.27.79zm5.18.44a1.66 1.66 0 01-1.2.47 1.68 1.68 0 01-1.21-.51A1.58 1.58 0 017 6.21 1.58 1.58 0 017.51 5a1.67 1.67 0 011.21-.47 1.62 1.62 0 011.2.47 1.58 1.58 0 01.49 1.19 1.58 1.58 0 01-.49 1.21zm2.34 1.1h-1.51v-.4l.63-.65a2.61 2.61 0 00.27-.31.4.4 0 00.08-.18.22.22 0 00-.07-.17.2.2 0 00-.17-.08c-.12 0-.25.09-.37.28l-.38-.22a1.07 1.07 0 01.33-.34.82.82 0 01.94 0 .56.56 0 01.2.47.53.53 0 01-.08.32 1.72 1.72 0 01-.31.39l-.38.35h.82z"
                                                    transform="translate(-.29 -.66)"
                                            />
                                            <path
                                                    class="cls-1"
                                                    d="M8.73 5.17a.91.91 0 00-.67.28.93.93 0 00-.28.68 1 1 0 001 1 .91.91 0 00.68-.28.88.88 0 00.28-.68.93.93 0 00-.28-.68.9.9 0 00-.73-.32z"
                                                    transform="translate(-.29 -.66)"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <div>CO₂减排量</div>
                                        <div>
                      <span>{{
                        (stationDetail.allPower * 0.000793).toFixed(2)
                      }}</span>
                                            棵
                                        </div>
                                    </div>
                                </div>
                                <div class="energy-item flex-alignc">
                                    <div class="item-img">
                                        <svg
                                                class="vaM tra-fast w100pct h100pct dpB"
                                                data-inject-url="https://pro.solarmanpv.com/static/icons/tree.svg"
                                                data-v-d7165444
                                                viewBox="0 0 22.9 22.4"
                                                xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g data-name="图层 2">
                                                <path
                                                        d="M9.36 18.55c1 0 1 .17 1 .84v1.84c0 .5 0 .83-.16 1-.17 0-.34.17-.51.17h-.33a1 1 0 01-.67-.17 1.23 1.23 0 01-.17-.83v-2c0-.85 0-.85.84-.85zM13.71 0c.16 0 .5.17.5.33l2.34 3.18 1.5 2 .5.5h-1.67l1.67 2.34c.67.83 1.17 1.67 2 2.67l.33.5h-2.5a13.19 13.19 0 011.84 2.34 37.22 37.22 0 002.5 3l.17.17-.17.17c-.16.16-.67.16-1.17.16h-5a2.06 2.06 0 00-.84.84v2.51c0 .67 0 1-.33 1.33a2.46 2.46 0 01-1.17.17c-1.17 0-1.67-.17-1.67-1.5v-2.32c0-.17-.17-.34-.34-.5s-.33-.34-.5-.34H3.84l.51-.5a34.11 34.11 0 012.34-3c.83-1 1.33-1.67 2-2.51H6.18l.34-.5c1-1 1.67-2 2.51-2.84a15.56 15.56 0 001.67-2H9l.66-.83L10.7 4A30.12 30.12 0 0113.21.5c.16-.33.33-.33.5-.5zM9 1.17l.33.33c1 1.17 1 1.34.17 2.51a24.52 24.52 0 01-1.64 2.17l-.17.34h1.5L7.19 9 6 10.7l-.67 1.17h1.19V12a1 1 0 01-.17.66l-.83 1.17c-.67.84-1.51 1.84-2.18 2.85a1.4 1.4 0 01-1.17.66H0l1.67-2 .5-.67 1.51-2 .16-.33H2.17l.34-.5 1.67-2.15L6 7.35H4.51l.5-.5L7 4.18l1.86-2.51z"
                                                        data-name="图层 1"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <div>
                                        <div>等效植树量</div>
                                        <div>
                      <span>{{
                        ((stationDetail.allPower * 0.997) / 18.3).toFixed(2)
                      }}</span>
                                            棵
                                        </div>
                                    </div>
                                </div>
                                <div class="energy-item flex-alignc">
                                    <div class="item-img">
                                        <svg
                                                class="vaM tra-fast w100pct h100pct dpB"
                                                data-inject-url="https://pro.solarmanpv.com/static/icons/totalIncome.svg"
                                                data-v-d7165444
                                                viewBox="0 0 16 16"
                                                xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M0 16h16V0H0z" fill="none"/>
                                            <path
                                                    clip-rule="evenodd"
                                                    d="M9.9 11.1c.2 0 .4.2.4.4s-.2.4-.4.4H8.1V13c0 .2-.2.4-.4.4s-.4-.2-.4-.4v-1.1H5.6c-.2 0-.3-.1-.4-.2-.1-.1-.1-.3 0-.4.1-.1.2-.2.4-.2h1.7v-.5H5.6c-.2 0-.3-.1-.4-.2-.1-.1-.1-.3 0-.4.1-.1.2-.2.4-.2h1.2L5.6 7.6v-.3c0-.1.1-.2.1-.3.2-.1.5-.1.6.1l1.4 2.6h.1l1.4-2.5c.1-.2.4-.3.6-.2.1.1.2.2.2.3v.3L8.8 9.7h1.1c.2 0 .4.2.4.4s-.2.4-.4.4H8.1v.5h1.8zm-.4-5.8H6c-3.9 0-4.9 7.6-4.9 7.6 0 1 .8 1.9 1.8 2h9.5c1-.1 1.8-1 1.8-2 .1 0-.9-7.6-4.7-7.6zM6 4.6h3.3c1 0 1.8-2.2 1.8-2.2 0-.7-.1-1.2-1.2-1.2-1.1-.1-1.4.8-2.2.8s-1.3-.7-2.3-.8-1.2.5-1.2 1.2c0 0 .7 2.2 1.8 2.2z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <div>累计收入</div>
                                        <div>
                      <span>{{
                        (
                          stationDetail.allPower *
                          stationDetail.stationEntity.unitPrice
                        ).toFixed(2)
                      }}</span>
                                            元
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>

                <!-- 子系统 -->
                <!--<a-row :gutter="16" class="mb-16">
                    <a-col :span="24">
                        <div class="bgfff">
                            <div class="flex-alignc-justifyb pd-20">
                                <div>子系统</div>
                                <div class="flex-alignc">
                                    <a-button @click="visible = true" size="small" type="primary"
                                    >添加子系统
                                    </a-button
                                    >
                                </div>
                            </div>
                            <div class="w100 pd-20">
                                <a-table
                                        :columns="systemColumns"
                                        :data-source="stationDetail.childStation"
                                        :scroll="{ x: true, y: 320 }"
                                >
                  <span slot="communicateSlot" slot-scope="text, record">
                    <span v-if="record.communicate == 1">正常</span>
                    <span v-else-if="record.communicate == 2"
                    >部分设备离线</span
                    >
                    <span v-else-if="record.communicate == 3"
                    >全部设备离线</span
                    >
                    <span v-else-if="record.communicate == 4">接入中</span>
                  </span>
                                    <span slot="alarmFlagSlot" slot-scope="text, record">
                    <span v-if="record.alarmFlag == 1">有报警</span>
                    <span v-else>无报警</span>
                  </span>
                                </a-table>
                            </div>
                        </div>
                    </a-col>
                </a-row>-->

                <!-- 逆变器排 计划发电量 -->
                <a-row :gutter="16" class="mb-16" style="height: 400px">
                    <a-col :span="24" class="h100">
                        <div class="bgfff h100 flex-directionc">
                            <div class="flex-alignc-justifyb pd-20">
                                <div>
                                    逆变器排名
                                    <span style="color: #95929c">限10个</span>
                                </div>
                                <a-icon type="right"/>
                            </div>
                            <div class="w100 pd-20 flex-sub1">
                                <div class="h100" ref="yHeight">
                                    <a-table
                                            :columns="inverterColumns"
                                            :data-source="inverterData"
                                            :pagination="false"
                                            :scroll="{ x: '100%', y: yHeight }"
                                    >
                    <span slot="avgActivePowerSlot" slot-scope="text, record">
                      <a-progress
                              v-if="record.avgActivePower"
                              :percent="record.avgActivePower * 100"
                      />
                    </span>
                                    </a-table>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>

                <!-- 系统概要 -->
                <a-row :gutter="16">
                    <a-col :span="24">
                        <div class="bgfff pd-20" style="padding-bottom: 0">
                            <div class="mb-16">系统概要</div>
                            <div class="flex-wrap pd-20 summary">
                                <div class="flex summary-item">
                                    <div class="item-img">
                                        <img src="../assets/station/summary1.png"/>
                                    </div>
                                    <div class="item-right">
                                        <div>
                                            逆变器总数
                                            <span>{{ stationDetail.inverterNum }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex summary-item">
                                    <div class="item-img">
                                        <img src="../assets/station/summary4.png"/>
                                    </div>
                                    <div class="item-right">
                                        <div>
                                            监控器总数
                                            <span>{{ stationDetail.monitorNum }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex summary-item">
                                    <div class="item-img">
                                        <img src="../assets/station/summary2.png"/>
                                    </div>
                                    <div class="item-right">
                                        <div>
                                            采集器总数
                                            <span>{{ stationDetail.collectorNum }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>

        <!-- 添加子系统弹窗 -->
        <a-modal
                :visible="visible"
                @cancel="handleCancel"
                @ok="handleOk"
                title="添加子系统"
        >
            <a-form-model :model="modalForm" :rules="modalrules" ref="modalForm">
                <a-form-model-item label="子系统名称" prop="stationName">
                    <a-input
                            v-model="modalForm.stationName"
                            placeholder="请输入子系统名称"
                    ></a-input>
                </a-form-model-item>
                <!-- 子系统类型==1 显示装机容量 -->
                <a-form-model-item label="装机容量(kWh)" prop="capacity">
                    <a-input
                            v-model="modalForm.capacity"
                            placeholder="请输入装机容量"
                    ></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <!-- 节能减排及收入提示弹窗 -->
        <a-modal
                title="节能减排及收入"
                :visible="energyShow"
                okText="我知道了"
                @cancel="energyShow = false"
        >
            <div>
                节约标准煤（吨）=0.000305*累计发电量（kWh），数据来自国家发改委<br/>
                CO₂减排量（吨）=0.000793*累计发电量（kWh），数据来自国家生态环境部<br/>
                等效植树量（棵）=累计发电量（kWh）*0.997/18.3，即1年内需要种多少棵树才能吸收掉CO₂减排量中的所有CO₂，数据来自IPCC<br/>
                累计收入=度电收益*累计发电量（kWh）
            </div>
            <template slot="footer">
                <a-button size="small" type="primary" @click="energyShow = false"
                >我知道了
                </a-button
                >
            </template>
        </a-modal>
        <a-modal :title="Title" :visible="snModal" @ok="snOk" @cancel="snCancel">
            <a-form-model ref="snModal" :model="snForm" :rules="snrules">
                <a-form-model-item label="sn编码" prop="collectorSn">
                    <a-input v-model="snForm.collectorSn"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import moment from "moment";
    import {
        powerSurveyEchart,
        historyEchart,
        historyEchartColumnar,
        planPowerEchart,
    } from "./mainData";
    import {
        getStationData,
        getStationHistoryData,
        downloadHistory,
        saveSingle,
        getInverterRanking,
    } from "@/api/station/main";

    import {
        deleteSingleStation,
        devicesaveSingle,
        addRobot,
    } from "@/api/powerStation.js";
    import Flow from "./components/Flow";
    import Flow4 from "./components/Flow4";
    import {exportDown} from "@/utils/index";
    import {debound} from "@/utils/index.js";
    // 子系统表格数据
    const systemColumns = [
        {title: "子系统名称", dataIndex: "stationName", width: 150, fixed: "left"},
        {
            title: "通讯状态",
            dataIndex: "communicate",
            width: 150,
            scopedSlots: {customRender: "communicateSlot"},
        },
        {
            title: "报警状态",
            dataIndex: "alarmFlag",
            width: 150,
            scopedSlots: {customRender: "alarmFlagSlot"},
        },
        {title: "发电功率", dataIndex: "allActivePower", width: 150},
        {title: "当日发电量", dataIndex: "dayPower", width: 150},
        // { title: '累计发电量', dataIndex: '', width: 150 },
        {title: "当日满发小时(h)", dataIndex: "fullHour", width: 150},
        {title: "功率归一化", dataIndex: "avgActivePower", width: 150},
        {title: "装机容量(kWh)", dataIndex: "capacity", width: 150},
    ];

    export default {
        components: {
            Flow,
            Flow4,
        },
        data() {
            return {
                moment,
                id: "",
                stationDetail: {
                    childStation: [], // 子系统表格数据
                    stationEntity: {}, // 电站详情数据
                    taskEntities: [], // 工单列表
                }, // 电站详情数据
                taskExecutionNum: 0, // 工单执行中数量
                taskCompletedNum: 0, // 工单已完成数量
                systemType: [
                    {
                        label: "流动图",
                        value: "1",
                        show: true,
                    },
                    {
                        label: "发电实时",
                        value: "2",
                        show: true,
                    },
                    {
                        label: "电网实时",
                        value: "4",
                        show: true,
                    },
                ],
                systemTypeValue: 1,

                //历史数据
                timeType: "1",
                historyTime: moment(),
                historyData: {
                    dayPower: "",
                    monthPower: "",
                    yearPower: "",
                    fullHour: "",
                },
                open: false,
                // 电能分析数据
                electricDate: "日",
                electricTime: moment(),
                // 子系统筛选参数
                systemParams: {
                    systemType: "发电", // 子系统类型
                    communicate: "",
                    alarmFlag: "",
                },
                systemColumns, // 子系统表格表头数据
                // 逆变器排名数据
                inverterData: [],
                yHeight: 0,
                inverterColumns: [
                    {title: "逆变器名称", dataIndex: "inverterTitle"},
                    {title: "当日满发小时(h)", dataIndex: "fullHour"},
                    {
                        title: "功率归一化",
                        dataIndex: "avgActivePower",
                        scopedSlots: {customRender: "avgActivePowerSlot"},
                    },
                ],
                // 计划
                planDate: "年",
                planTime: moment(),

                visible: false, // 添加子系统弹窗
                modalForm: {
                    // 添加子系统参数
                    parentId: "", // 电站id
                    stationName: "", // 子系统名称
                    type: "2", // 子系统类型 1 发电 2 用电
                    capacity: "", // 装机容量
                },
                modalrules: {
                    stationName: [
                        {required: true, message: "请输入子系统名称", trigger: "blur"},
                    ],
                    capacity: [
                        {required: true, message: "请输入装机容量", trigger: "blur"},
                        // 子系统类型 放开时用这个校验
                        // { required: true,trigger: 'blur',validator: (rule, value, callback) => {
                        //     if (this.modalForm.type==1&&!value) {
                        //       callback('请输入装机容量')
                        //     }else{
                        //       callback()
                        //     }
                        //   }
                        // }
                    ],
                },
                energyShow: false, // 节能减排及收入提示弹窗
                snForm: {
                    collectorSn: "",
                },
                snModal: false,
                flag: 0,
                snrules: {
                    collectorSn: [
                        {required: true, message: "请输入sn编码", trigger: "blur"},
                    ],
                },
                historyVm: null,
                echartDom: null,
                rfpFKwh: 0,
                fpFKwh: 0,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.modalForm.parentId = this.$route.query.id;
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.screenResizec);
        },
        mounted() {
            this.init();
            this.watchResize();
        },
        methods: {
            watchResize() {
                this.yHeight = this.$refs["yHeight"].offsetHeight - 55;
                window.addEventListener("resize", this.screenResize);
            },
            screenResize: debound(function () {
                // document.documentElement.clientHeight;
                this.yHeight = this.$refs["yHeight"].offsetHeight - 55;
            }, 200),

            toPath(path) {
                let stationId = sessionStorage.getItem("stationId");
                this.$router.push({
                    path,
                    query: {
                        id: stationId,
                    },
                });
            },

            handleClickMore({key}) {
                if (key == 1) {
                    // 删除电站
                    this.$confirm({
                        title: `确认删除该电站吗?`,
                        okText: "确认",
                        cancelText: "取消",
                        onOk: () => {
                            deleteSingleStation({id: this.id}).then((res) => {
                                this.$message.success("删除成功");
                                this.$router.push({path: "/monitoring/powerstation"});
                            });
                        },
                    });
                }
            },
            init() {
                // 获取电站详情数据
                this.getStationData();
                // 电站子系统逆变器排名
                this.getInverterRanking();
                // 初始化天气
                this.initWeather();
                // 初始化图表
                this.initEcharts();
            },
            // 添加子系统
            handleOk() {
                this.$refs.modalForm.validate((valid) => {
                    if (valid) {
                        saveSingle(this.modalForm).then((res) => {
                            if (res.code == "000000") {
                                this.$message.success({
                                    content: "添加成功",
                                });
                                this.getStationData();
                                this.visible = false;
                            } else {
                                this.$message.error({
                                    content: res.message,
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleCancel() {
                this.$refs.modalForm.resetFields();
                this.visible = false;
            },

            // 切换系统类型
            handleSystemType(item) {
                if (this.systemTypeValue == item.value) return;

                this.systemTypeValue = item.value;
                if (this.systemTypeValue != 2 && this.echartDom) {
                    this.echartDom.clear();
                    console.log(this.stationDetail, "----详情");
                }
                if (item.value == 2) {
                    this.initPowerEcharts();
                }
            },
            // 获取电站详情数据
            async getStationData() {
                try {
                    let res = await getStationData({id: this.id});
                    // 工单执行中数量
                    // this.taskExecutionNum = res.data.taskEntities.filter((item) => {
                    //     return item.taskStatus != 6;
                    // }).length;
                    // 工单已完成数量
                    // this.taskCompletedNum = res.data.taskEntities.filter((item) => {
                    //     return item.taskStatus == 6;
                    // }).length;
                    this.stationDetail = res.data;
                    this.fpFKwh = res.data.fpFKwh;
                    this.rfpFKwh = res.data.rfpFKwh;
                    if (this.stationDetail.stationEntity.systemType == 1) {
                        // 处理流动图tab显示
                        this.systemType[0].show =
                            this.systemType[2].show =
                                this.systemType[3].show =
                                    false;
                        this.systemTypeValue = 2;
                        this.initPowerEcharts();
                    } else if (this.stationDetail.stationEntity.systemType == 2) {
                        this.systemType[4].show = false;
                    }
                } catch (err) {
                }
            },
            // 电站子系统逆变器排名
            async getInverterRanking() {
                try {
                    let res = await getInverterRanking({id: this.id});
                    res.data.forEach(function ( item, index ) {
	                    res.data.inverterTitle = '逆变器'
                    })
                    this.inverterData = res.data;
                } catch (err) {
                }
            },

            // 初始化发电实时数据
            initPowerEcharts() {
                this.$nextTick(() => {
                    const waterPolo = document.getElementById("waterpolo", null, {
                        devicePixelRatio: window.devicePixelRatio,
                    });
                    this.echartDom = this.echarts.init(waterPolo);
                    const proportion = (
                        this.stationDetail.allActivePower /
                        this.stationDetail.stationEntity.capacity /
	                        1000
                    ).toFixed(4);
                    this.echartDom.setOption(powerSurveyEchart(proportion));
                });
            },
            // 时间类型切换
            changeTimeType() {
                // console.log(this.historyDate)
                // console.log(moment(this.historyTime).format("YYYY-MM-DD 00:00:00"))
                this.initEcharts();
            },
            // 时间选择
            changeTime() {
                // console.log(moment(this.historyTime).format("YYYY-MM-DD 00:00:00"))
                this.initEcharts();
            },
            dealYM(num) {
                let time = moment(this.historyTime);
                if (this.timeType == 1) {
                    this.historyTime = num < 0 ? time.subtract(1, "d") : time.add(1, "d");
                } else if (this.timeType == 2) {
                    this.historyTime = num < 0 ? time.subtract(1, "M") : time.add(1, "M");
                } else if (this.timeType == 3) {
                    this.historyTime = num < 0 ? time.subtract(1, "y") : time.add(1, "y");
                }
                this.initEcharts();
            },
            // 历史数据导出
            handleExport() {
                let startTime, endTime;
                startTime = endTime = this.historyTime;
                if (this.timeType == 2) {
                    startTime = moment(this.historyTime).startOf("month");
                    endTime = moment(this.historyTime).endOf("month");
                } else if (this.timeType == 3) {
                    startTime = moment(this.historyTime).startOf("year");
                    endTime = moment(this.historyTime).endOf("year");
                }
                downloadHistory({
                    id: this.id,
                    timeType: this.timeType,
                    startTime: startTime.format("YYYY-MM-DD 00:00:00"),
                    endTime: endTime.format("YYYY-MM-DD 00:00:00"),
                }).then((res) => {
                    exportDown(
                        res,
                        `${this.stationDetail.stationEntity.stationName}发电历史`
                    );
                });
            },
            // 初始化 历史 计划发电图标数据
            initEcharts() {
                let startTime, endTime;
                startTime = endTime = this.historyTime;
                if (this.timeType == 2) {
                    startTime = moment(this.historyTime).startOf("month");
                    endTime = moment(this.historyTime).endOf("month");
                } else if (this.timeType == 3) {
                    startTime = moment(this.historyTime).startOf("year");
                    endTime = moment(this.historyTime).endOf("year");
                }
                getStationHistoryData({
                    id: this.id,
                    timeType: this.timeType,
                    startTime: startTime.format("YYYY-MM-DD 00:00:00"),
                    endTime: endTime.format("YYYY-MM-DD 00:00:00"),
                }).then((res) => {
                    this.historyData.fullHour = res.data.fullHour;
                    delete res.data.fullHour;
                    if (this.timeType == 1) {
                        this.historyData.dayPower = res.data.dayPower;
                        delete res.data.dayPower;
                    } else if (this.timeType == 2) {
                        this.historyData.monthPower = res.data.monthPower;
                        delete res.data.monthPower;
                    } else if (this.timeType == 3) {
                        this.historyData.yearPower = res.data.yearPower;
                        delete res.data.yearPower;
                    }
                    let xData = [],
                        yData = {
                            0: [], // 每个时间节点的日发电量
                            1: [], // 每个时间节点的发电功率
                        };
                    xData = Object.keys(res.data);
                    xData.sort((a, b) => {
                        return moment(a).unix() - moment(b).unix();
                    });
                    if (this.timeType == 1) {
                        xData.forEach((item) => {
                            yData["0"].push((res.data[item].todayEnergy).toFixed(2));
                            yData["1"].push((res.data[item].gridPower / 1000).toFixed(2));
                        });
                        xData = xData.map((item) => {
                            return moment(item).format("HH:mm:ss");
                        });
                    } else if (this.timeType == 2 || this.timeType == 3) {
                        xData.forEach((item) => {
                            yData["0"].push(res.data[item]);
                        });
                    }
                    if (this.historyVm) {
                        this.historyVm.dispose(); //销毁
                    }
                    this.historyVm = null;

                    // 历史
                    const history = document.getElementById("history", null, {
                        devicePixelRatio: window.devicePixelRatio,
                    });
                    this.historyVm = this.echarts.init(history);
                    if (this.timeType == 1) {
                        this.historyVm.setOption(
                            historyEchart({
                                xData,
                                yData,
                            })
                        );
                    } else {
                        this.historyVm.setOption(
                            historyEchartColumnar({
                                xData,
                                yData,
                            })
                        );
                    }
                });

                // 计划发电 planpower 暂无
                // const planpower = document.getElementById('planpower', null, {
                //   devicePixelRatio: window.devicePixelRatio
                // })
                // const planpowerVm = this.echarts.init(planpower)
                // planpowerVm.setOption(planPowerEchart())
            },
            // 年份选择
            openChange(val) {
                if (val) {
                    this.open = true;
                } else {
                    this.open = false;
                }
            },
            panelChange(val) {
                this.historyTime = moment(val);
                this.open = false;
            },
            // 初始化天气
            initWeather() {
                window.WIDGET = {
                    CONFIG: {
                        layout: "2",
                        width: 230,
                        height: 270,
                        background: "5",
                        dataColor: "000000",
                        aqiColor: "000000",
                        city: '340181',  // 巢湖城市编码
                        key: "1994135d2429415bb07d1c356af36a84",
                    },
                };
                (function (d) {
                    var c = d.createElement("link");
                    c.rel = "stylesheet";
                    c.href =
                        "https://widget.qweather.net/standard/static/css/he-standard.css?v=1.4.0";
                    var s = d.createElement("script");
                    s.src = "https://widget.qweather.net/standard/static/js/he-standard.js?v=1.4.0";
                    var sn = d.getElementsByTagName("script")[0];
                    sn.parentNode.insertBefore(c, sn);
                    sn.parentNode.insertBefore(s, sn);
                })(document);
            },
            // 提交采集、监控
            snOk() {
                this.$refs.snModal.validate((valid) => {
                    if (valid) {
                        const requestData = {
                            stationId: this.id,
                            deviceSn: this.snForm.collectorSn,
                            deviceType: this.flag,
                        };
                        const robotData = {
                            stationId: this.id,
                            robotSn: this.snForm.collectorSn,
                        };
                        let requestApi = this.flag == 6 ? addRobot : devicesaveSingle;
                        requestApi(this.flag == 6 ? robotData : requestData)
                            .then((res) => {
                                if (res.code == "000000") {
                                    this.$message.success({
                                        content: this.Title + "成功",
                                    });
                                    this.snModal = false;
                                    // this.$emit("requestTable", this.pagination);
                                } else {
                                    this.$message.error({
                                        content: res.message,
                                    });
                                }
                            })
                            .catch(() => {
                                this.$message.error({
                                    content: this.Title + "失败",
                                });
                            });
                    } else {
                        return false;
                    }
                });
            },
            // 关闭采集、监控modal，重置表单
            snCancel() {
                this.$refs.snModal.resetFields();
                this.snModal = false;
            },
            // 打开采集、监控modal
            openModal(index) {
                this.flag = index;
                this.snModal = true;
                this.snForm.collectorSn = "";
            },
        },
        computed: {
            Title() {
                if (this.flag == 1) {
                    return "添加采集器"
                } else if (this.flag == 2) {
                    return "添加监控器"
                } else if (this.flag == 4) {
                    return "添加优化器"
                } else {
                    return "添加机器人"
                }
                // return this.flag == 1
                //   ? "添加采集器"
                //   : this.flag == 2
                //   ? "添加监控器"
                //   : "添加机器人";
            },
            usePower() {
                return new Number(this.stationDetail.allPower) + new Number(this.rfpFKwh);
            },
        },
    };
</script>

<style>
    @keyframes arrowAnimation {
        0% {
            opacity: 0;
            border-color: #0fb9b0;
            box-shadow: 0 0 0 sizeMultiplier(0.125) #0fb9b055;
            offset-distance: 0%;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            border-color: #f7a219;
            box-shadow: 0 0 0 sizeMultiplier(0.125) #f7a21955;
            offset-distance: 100%;
        }
    }

    @keyframes arrowAnimation1 {
        0% {
            opacity: 0;
            border-color: #0fb9b0;
            box-shadow: 0 0 0 sizeMultiplier(0.125) #0fb9b055;
            offset-distance: 100%;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            border-color: #f7a219;
            box-shadow: 0 0 0 sizeMultiplier(0.125) #f7a21955;
            offset-distance: 0%;
        }
    }
</style>

<style lang="less" scoped>
    .echarts-box {
        #history {
            width: 100%;
            height: 500px;
        }

        #planpower {
            width: 100%;
            height: 100%;
        }
    }

    .section-header {
        background-color: #ffffff;

        .fL {
            float: left;
            width: 60%;
            color: #95929c;

            .lh13 {
                color: #252b3a;
                font-weight: bold;
                font-size: 28px;
            }

            .fL-id {
                margin-right: 50px;
            }
        }

        .fR {
            float: right;
            width: 40%;
            text-align: right;
        }
    }

    ::v-deep #he-plugin-standard {
        width: 100% !important;
        height: 100% !important;
    }

    .station-row {
        height: 500px;
    }

    .station-cover {
        position: relative;
        width: 100%;
        height: 280px;
        // background: url(../assets/station/cover.jpg) center center / cover no-repeat;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .cover-text {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 50px;
            padding: 0px 30px;
            line-height: 50px;
            color: rgb(255, 255, 255);
            text-align: center;
            z-index: 2;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 50px;
        }

        .cover-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            object-fit: cover;
        }
    }

    .data-echarts {
        width: 100%;
        height: 260px;
        display: flex;

        .echarts-left {
            flex: 6;
            display: flex;
            justify-content: center;

            div {
                width: 160px;
                height: 100%;
            }
        }

        .echarts-right {
            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .center-box {
                .total-power {
                    color: #95929c;
                    font-size: 12px;

                    .total-title {
                        margin-bottom: 4px;
                    }

                    .total-num {
                        span {
                            font-size: 20px;
                            color: #252b3a;
                        }
                    }
                }

                .right-line {
                    width: 136px;
                    margin-top: 4px;
                    margin-bottom: 8px;
                    border-bottom: 1px solid #e3e3e7;
                }
            }
        }
    }

    .row-line {
        width: 100%;
        height: 8px;
        border-bottom: 1px solid #e3e3e7;
        margin-bottom: 12px;
    }

    .statistics-box {
        // padding: 0 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .one-box {
            flex: 1;
            margin: 0 8px;
            // width: 165px;
            padding: 20px 8px 0 8px;
            box-sizing: border-box;
            font-size: 12px;
            color: #515561;

            .statistics-num {
                margin: 4px 0;

                span {
                    font-size: 1px;
                    color: #515561;
                }
            }
        }
    }

    .systemType-row1 {
        margin-bottom: 40px;

        .img {
            position: relative;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            text-align: center;
            border-radius: 50%;
            color: #f76457;
            fill: #f76457;
            background-color: #f764571a;
            text-align: center;
            line-height: 50px;

            svg {
                position: absolute;
                top: 50%;
                height: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 30px;
                fill: rgb(247, 100, 87);
            }
        }

        .img1 {
            color: #0fb9b0;
            fill: #30bf78;
            background-color: #0fb9b01a;

            svg {
                fill: #0fb9b0;
            }
        }

        .img2 {
            color: #30bf78;
            fill: #0fb9b0;
            background-color: #30bf781a;

            svg {
                fill: #30bf78;
            }
        }

        .text {
            span:nth-child(2) {
                font-weight: bold;
                font-size: 28px;
                color: #333333;
            }
        }
    }

    .systemType3 {
        .banner-list {
            justify-content: space-between;

            .item {
                padding: 30px 40px;
                width: 49%;
                margin-bottom: 20px;

                span {
                    font-size: 28px;
                    color: #333333;
                }
            }
        }
    }

    .systemType4 {
        .banner-list {
            .item {
                padding: 15px 25px;
                margin-bottom: 13px;

                .left {
                    padding-right: 35px;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }

                .right-item {
                    padding-left: 20px;

                    span {
                        font-size: 28px;
                    }
                }

                .power-num {
                    height: 50px;
                    line-height: 50px;
                    margin-left: 40px;
                }
            }
        }
    }

    .station-msg {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        li {
            .lable {
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }

    .flow-tab {
        margin-bottom: 0;

        li {
            margin-right: 20px;
            color: rgba(0, 0, 0, 0.45);

            &.on {
                color: #515561;
            }

            &:hover {
                color: #515561;
            }
        }
    }

    .flow-content {
        padding: 15px;
    }

    .history-tab {
        color: rgba(0, 0, 0, 0.45);

        /*.right-line {
            border-right: 1px solid #e3e3e7;
        }*/

        .num {
            color: #333333;

            span {
                font-size: 32px;
            }
        }
    }

    .electric {
        display: flex;
        flex-direction: column;

        .electric-btm {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .electric-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 30px;
            padding-bottom: 30px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            .item-name {
                font-size: 30px;
                color: #252b3a;
            }

            .item-progress {
                display: flex;
                justify-content: space-between;

                div {
                    position: relative;
                    white-space: nowrap;
                    border-radius: 5px;
                    height: 50px;
                    line-height: 50px;
                    transition: 0.5s cubic-bezier(0.6, 0, 0.4, 1);
                    color: #fff;
                    padding: 0 10px;
                }

                .progress-left {
                    background-color: rgb(4, 143, 255);
                    margin-right: 5px;
                    text-align: left;
                }

                .progress-right {
                    background-color: rgb(158, 94, 217);
                    margin-left: 5px;
                    text-align: right;
                }
            }
        }
    }

    .work-order {
        color: rgba(0, 0, 0, 0.45);
        font-size: 20px;

        .num {
            margin-bottom: 50px;
            font-size: 60px;
            font-weight: bold;
        }

        .numl {
            color: #048fff;
        }

        .numr {
            color: #41d068;
        }
    }

    .energy {
        // padding-top: 40px;
        .energy-item {
            width: 50%;
            color: #95929c;
            // margin-bottom: 50px;
            padding-left: 60px;

            .item-img {
                width: 50px;
                height: 50px;
                margin-right: 30px;
                fill: #048fff;
            }

            span {
                color: #333333;
                font-size: 28px;
                font-weight: bold;
            }
        }
    }

    .summary {
        .summary-item {
            width: 33%;
            margin-bottom: 30px;

            .item-img {
                width: 50px;
                height: 50px;
                margin-right: 30px;
                fill: rgba(0, 0, 0, 0.45);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-right {
                line-height: 40px;

                span {
                    color: #252b3a;
                    font-size: 30px;
                }
            }
        }
    }
</style>
